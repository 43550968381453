import React from 'react'
import styled from '@emotion/styled'
import { locations } from './data'

const LocationCarouselContainer = styled('div')`
  overflow: auto;
  display: flex;
  margin-left: -65px;
  margin-right: -65px;
  padding-left: 65px;
  padding-right: 65px;
  margin-top: 30px;
  align-items: center;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 20px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }
`

const LocationImage = styled('img')`
  border: ${props =>
    props.active ? `1px solid ${props.locationColor}` : 'none'};
  border-radius: 50%;
  width: 94px;

  + ${() => LocationImage} {
    margin-left: 10px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    width: 80px;

    + ${() => LocationImage} {
      margin-left: 0;
    }
  }
`

export default function LocationCarousel({
  currentLocation,
  setCurrentLocation,
}) {
  const handleImageClick = selectedLocation => {
    setCurrentLocation(selectedLocation)
  }

  return (
    <LocationCarouselContainer>
      {locations.map((location, i) => (
        <LocationImage
          key={i}
          src={location.image}
          srcset={`${location.image2x} 2x`}
          onClick={() => handleImageClick(location)}
          active={location.name === currentLocation.name}
          locationColor={location.color}
        />
      ))}
    </LocationCarouselContainer>
  )
}
