import React from 'react'
import styled from '@emotion/styled'

const MainImageCard = styled('div')`
  background-color: #f2f1ef;
  max-width: calc(50% - 35px);
  border-radius: 5px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: calc(50% - 10px);
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: unset;
    width: 100%;
    margin-bottom: 13px;
  }
`

const SmallImage = styled('div')`
  max-width: 45%;
  img {
    border-radius: 5px;
    width: 100%;
    vertical-align: bottom;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    &:nth-child(n + 3) {
      margin-top: 13px;
    }
  }
`

const MainImageDetails = styled('div')`
  background: #fff;
  border-radius: 5px;
  z-index: 1;
  position: relative;
  width: calc(100% - 30px);
  padding: 27px 15% 18px 27px;
  margin: -60px auto 15px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 10px 30px 10px 16px;
    margin-top: -45px;
  }
`

const MainImage = styled('img')`
  width: 100%;
  border-radius: 5px 5px 0 0;
`

const ImagesContainer = styled('div')`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    flex-direction: column;
    margin-left: -20px;
    margin-right: -20px;
  }
`

const ImageGridContainer = styled('div')`
  max-width: calc(50% - 35px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: calc(50% - 10px);
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: unset;
    width: 100%;
  }
`

const JobTitle = styled('p')`
  margin: 0;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 12px;
    line-height: 13px;
  }
`

const MainImageName = styled('p')`
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 0;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 12px;
    line-height: 13px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
`

const MainImageQuote = styled('p')`
  font-size: 24px;
  line-height: 22px;
  font-style: italic;
  color: rgba(98, 98, 98, 0.9);
  margin: 0;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 14px;
    line-height: 12px;
  }
`

export default function Headshots(props) {
  const { whoMainImage, whoPeopleImages } = props
  return (
    <ImagesContainer>
      <MainImageCard>
        <MainImage
          src={whoMainImage.imageSerious.sizes.src}
          alt={`${whoMainImage.name} ${whoMainImage.lastName}, ${whoMainImage.title}`}
        />
        <MainImageDetails>
          <MainImageQuote>{whoMainImage.quote}</MainImageQuote>
          <MainImageName>{`${whoMainImage.name} ${whoMainImage.lastName}`}</MainImageName>
          <JobTitle>{whoMainImage.title}</JobTitle>
        </MainImageDetails>
      </MainImageCard>
      <ImageGridContainer>
        {whoPeopleImages.map(person => (
          <SmallImage key={person.name}>
            <img
              src={person.imageSerious.sizes.src}
              alt={`${person.name} ${person.lastName}, ${person.title}`}
            />
          </SmallImage>
        ))}
      </ImageGridContainer>
    </ImagesContainer>
  )
}
