import React from 'react'
import styled from '@emotion/styled'
import { CTA, CTAText, GradientButton } from '../../../Buttons'
import Title from '../../TitleWithStyledPeriod/TitleWithStyledPeriod'
import Container from '../../Container/Container'
import BackgroundImage from '../../BackgroundImage/BackgroundImage'

const Section = styled('div')`
  background-color: ${props => props.backgroundColor || 'transparent'};
  position: relative;
  padding-top: 255px;
  padding-bottom: 80px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 181px;
    padding-bottom: 150px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

const StyledCTA = styled(CTA)`
  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 14px;
    height: 42px;
    line-height: 42px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: 12px;
  }
`

const CTAButton = StyledCTA.withComponent('a')

const TitleContainer = styled('div')`
  max-width: 753px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 506px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: unset;
  }
`

const ContentContainer = styled('div')`
  position: relative;
  height: 100%;

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute;
  top: 234px;
  right: 0;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    top: 164px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    top: 183px;
  }
`

const Text = styled('p')`
  font-size: 30px;
  line-height: 34px;
  font-weight: normal;
  font-family: ${props => props.theme.fonts.secondaryBook};

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 24px;
    line-height: 27px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 18px;
    line-height: 20px;
  }
`

const TextContainer = styled('div')`
  max-width: 506px;
  margin-top: 200px;
  margin-left: auto;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 320px;
    margin-top: 90px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: 50px;
    margin-left: 0;
    text-align: center;

    p {
      text-align: left;
    }
  }
`

export default function ContentBlock(props) {
  const {
    heroTitle,
    heroText,
    heroCTAText,
    backgroundDesktop,
    backgroundTablet,
    backgroundMobile,
    ctaHref,
    gatsbyLink,
    backgroundColor,
  } = props

  const CTAButtonType = {
    regular: CTAButton,
    gatsby: GradientButton,
  }

  const Button = props => {
    const Component = CTAButtonType[props.ctaType]
    return <Component {...props} />
  }

  return (
    <Section backgroundColor={backgroundColor}>
      <StyledBackgroundImage
        desktop={backgroundDesktop}
        tablet={backgroundTablet}
        mobile={backgroundMobile}
      />
      <Container>
        <ContentContainer>
          <TitleContainer>
            <Title>{heroTitle}</Title>
          </TitleContainer>
          <TextContainer>
            <Text>{heroText}</Text>
            <Button
              href={ctaHref}
              whiteBtn={true}
              gradientLeft="1px"
              gradientTop="1px"
              gradientHeight="calc(100% - 2px)"
              gradientWidth="calc(100% - 2px)"
              fontWeight="500"
              fontFamily="primaryMedium"
              padding="0 42px"
              marginTop="17px"
              ctaType={gatsbyLink ? 'gatsby' : 'regular'}
            >
              <CTAText>{heroCTAText}</CTAText>
            </Button>
          </TextContainer>
        </ContentContainer>
      </Container>
    </Section>
  )
}
