import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import StickyNav from '../components/Navigation/StickyNav'
import Careers from '../components/Careers'
import { Helmet } from 'react-helmet'
import { ParallaxProvider } from 'react-scroll-parallax'
import OGImage from '../images/careers/CareersCover.jpg'

const metaTitle =
  'Careers - Join Toronto’s Leading Digital Product Studio | Crowdlinker'

const metaDescription =
  'Interested in working with Toronto’s leading designers, developers, strategists, and marketers? Apply for a chance to work on impactful digital products used by millions around the world.'

const CareersPage = props => {
  const { siteUrl } = props.data.site.siteMetadata
  const { path } = props
  const pageURL = siteUrl + path

  // old contentful data
  // const careersData = props.data.contentfulCareers
  const careersData = transformDataToFields(props.data.prismicCareers.data)

  return (
    <ParallaxProvider>
      <Layout hideFooterCareers bgColor="transparent">
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta property="og:url" content={pageURL} />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={metaDescription} />
          <meta property="og:image" content={siteUrl + OGImage} />
          <meta property="og:image:secure_url" content={siteUrl + OGImage} />
          <meta name="twitter:image" content={siteUrl + OGImage} />
        </Helmet>
        <StickyNav />
        <Careers careersData={careersData} />
      </Layout>
    </ParallaxProvider>
  )
}

export default CareersPage

export const careersQuery = graphql`
  {
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismicCareers {
      data {
        hero_image {
          document {
            data {
              hero_background {
                url
              }
              hero_background_tablet {
                url
              }
              hero_background_tablet_2x {
                url
              }
              hero_mobile_background {
                url
              }
              hero_mobile_background_2x {
                url
              }
            }
          }
        }

        perks_title {
          text
        }
        perks {
          perk_icon {
            url
          }
          perk_subtitle {
            text
          }
          perk_title {
            text
          }
        }

        location_subtitle {
          text
        }
        location_title {
          text
        }

        jobs_title {
          text
        }
        jobs_steps {
          jobs_step_color
          jobs_step_subtitle {
            text
          }
          jobs_step_title {
            text
          }
        }

        banner_cta {
          text
        }
        banner_title {
          text
        }
        banner_url {
          url
        }

        general_post_application_url {
          url
        }
        general_post_cta {
          text
        }
        general_post_message {
          text
        }

        departments {
          department_name {
            text
          }
          department_color
        }

        values_title {
          text
        }
        values_subtitle {
          text
        }
        values_item {
          values_title_color
          values_item_title {
            text
          }
          values_item_subtitle {
            text
          }
        }

        friendship_photos_title {
          text
        }
        friendship_photos_subtitle {
          text
        }

        hero_cta_text {
          text
        }
        hero_text {
          text
        }
        hero_title {
          text
        }
        who_cta_text {
          text
        }
        who_main_image {
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 545, maxHeight: 346, quality: 100) {
                src
              }
            }
          }
        }
        who_main_last_name {
          text
        }
        who_main_name {
          text
        }
        who_main_title {
          text
        }
        who_main_quote {
          text
        }
        who_people_images {
          who_person_image {
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 506, maxHeight: 430, quality: 100) {
                  src
                }
              }
            }
          }
          who_person_last_name {
            text
          }
          who_person_name {
            text
          }
          who_person_quote {
            text
          }
        }
        who_text {
          text
        }
        who_title {
          text
        }
      }
    }
  }
`

// transform prismic query results to configured contentful fields
function transformDataToFields(data) {
  const result = {
    heroTitle: data.hero_title.text,
    heroText: {
      heroText: data.hero_text.text,
    },
    heroCTAText: data.hero_cta_text.text,

    whoMainImage: {
      name: data.who_main_name.text,
      lastName: data.who_main_last_name.text,
      title: data.who_main_title.text,
      imageSerious: {
        sizes: {
          src: data.who_main_image.localFile.childImageSharp.fluid.src,
        },
      },
      quote: data.who_main_quote.text,
    },

    whoTitle: data.who_title.text,
    whoText: {
      whoText: data.who_text.text,
    },

    whoCTAText: data.who_cta_text.text,

    whoPeopleImages: data.who_people_images.map(person => {
      return {
        name: person.who_person_name.text,
        lastName: person.who_person_last_name.text,
        title: '',
        imageSerious: {
          sizes: {
            src: person.who_person_image.localFile.childImageSharp.fluid.src,
          },
        },
        quote: person.who_person_quote.text,
      }
    }),

    valuesTitle: data.values_title.text,
    valuesSubtitle: data.values_subtitle.text,
    values: data.values_item.map(value => {
      return {
        name: value.values_item_title.text,
        description: value.values_item_subtitle.text,
        color: value.values_title_color,
      }
    }),

    friendshipPhotosTitle: data.friendship_photos_title.text,
    friendshipPhotosSubtitle: data.friendship_photos_subtitle.text,

    perksTitle: data.perks_title.text,
    perks: data.perks.map(perk => {
      return {
        image: perk.perk_icon.url,
        title: perk.perk_title.text,
        description: perk.perk_subtitle.text,
      }
    }),

    locationTitle: data.location_title.text,
    locationSubtitle: data.location_subtitle.text,

    jobsTitle: data.jobs_title.text,
    applicationSteps: data.jobs_steps.map(step => {
      return {
        title: step.jobs_step_title.text,
        description: step.jobs_step_subtitle.text,
        color: step.jobs_step_color,
      }
    }),

    departmentColors: data.departments.map(department => {
      return {
        color: department.department_color,
        name: department.department_name.text,
      }
    }),

    heroImage: {
      heroBg: data.hero_image.document[0].data.hero_background.url,
      heroBgTablet: data.hero_image.document[0].data.hero_background_tablet.url,
      heroBgTablet2x:
        data.hero_image.document[0].data.hero_background_tablet_2x.url,
      heroBgMobile: data.hero_image.document[0].data.hero_mobile_background.url,
      heroBgMobile2x:
        data.hero_image.document[0].data.hero_mobile_background_2x.url,
    },

    bannerText: data.banner_title.text,
    bannerCtaText: data.banner_cta.text,
    bannerHref: data.banner_url.url,

    generalPostMessage: data.general_post_message.text,
    generalPostCtaText: data.general_post_cta.text,
    generalPostApplicationHref: data.general_post_application_url.url,
  }

  return result
}

// old contentful query:

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         siteUrl
//       }
//     }
//     contentfulCareers {
//       heroTitle
//       heroText {
//         heroText
//       }
//       heroCTAText
//       whoTitle
//       whoText {
//         whoText
//       }
//       whoCTAText
//       whoPeopleImages {
//         id
//         name
//         lastName
//         title
//         imageSerious {
//           title
//           sizes(maxWidth: 1020, maxHeight: 681, quality: 100) {
//             ...GatsbyContentfulSizes
//           }
//         }
//       }
//       whoMainImage {
//         name
//         lastName
//         title
//         imageSerious {
//           title
//           sizes(maxWidth: 1020, maxHeight: 681, quality: 100) {
//             ...GatsbyContentfulSizes
//           }
//         }
//       }
//     }
//   }
// `
