import React from 'react'
import styled from '@emotion/styled'
import Perk from './Perk'

const PerksContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 80px;
  padding-left: 55px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-left: 0;
    margin-top: 80px;
  }
`

export default function PerksList({ perks }) {
  return (
    <PerksContainer>
      {perks.map((perk, i) => (
        <Perk key={i} perk={perk} />
      ))}
    </PerksContainer>
  )
}
