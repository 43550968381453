import React from 'react'
import styled from '@emotion/styled'
import useWindowSize from '../../../util/useWindowSize'
import Title from '../../Shared/TitleWithStyledPeriod/TitleWithStyledPeriod'
import BackgroundContainer from '../../Shared/BackgroundContainer/BackgroundContainer'
import Container from '../../Shared/Container/Container'
import BackgroundImage from '../../Shared/BackgroundImage/BackgroundImage'
import Mosaic from './Mosaic'
import ScrollableImages from './ScrollableImages'
import BackgroundDesktop from '../../../images/linker-outline-grey-desktop.svg'
import BackgroundTablet from '../../../images/linker-outline-grey-tablet.svg'
import BackgroundMobile from '../../../images/linker-outline-grey-mobile.svg'
import Photo1 from '../../../images/careers/careers-team-1.png'
import Photo2 from '../../../images/careers/careers-team-2.png'
import Photo3 from '../../../images/careers/careers-team-3.png'
import Photo4 from '../../../images/careers/careers-team-4.png'
import Photo5 from '../../../images/careers/careers-team-5.png'
import Photo6 from '../../../images/careers/careers-team-6.png'
import Photo7 from '../../../images/careers/careers-team-7.png'
import Photo8 from '../../../images/careers/careers-team-8.png'
import Photo9 from '../../../images/careers/careers-team-9.png'

const photos = [
  Photo1,
  Photo2,
  Photo3,
  Photo4,
  Photo5,
  Photo6,
  Photo7,
  Photo8,
  Photo9,
]

const Section = styled('div')`
  padding-top: 125px;
  padding-bottom: 125px;
  position: relative;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 102px;
    padding-bottom: 80px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 81px;
    padding-bottom: 61px;
  }
`

const StyledTitle = styled(Title)`
  max-width: 544px;
`

const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute;
  top: 71px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    top: 80px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    top: 158px;
  }
`

const Text = styled('p')`
  font-size: 30px;
  line-height: 34px;
  font-weight: normal;
  max-width: 589px;
  display: flex;
  margin-left: auto;
  margin-top: 170px;
  font-family: ${props => props.theme.fonts.secondaryBook};

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 24px;
    line-height: 27px;
    max-width: 309px;
    margin-top: 80px;
    margin-bottom: 50px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 18px;
    line-height: 20px;
    margin-top: 72px;
    margin-bottom: 37px;
  }
`

export default function FriendshipPhotos({ title, subtitle }) {
  const screenSize = useWindowSize()
  return (
    <BackgroundContainer backgroundColor="#fff">
      <Section>
        <StyledBackgroundImage
          desktop={BackgroundDesktop}
          tablet={BackgroundTablet}
          mobile={BackgroundMobile}
        />
        <Container>
          <StyledTitle>{title}</StyledTitle>
          <Text>{subtitle}</Text>
          {screenSize === 'mobile' ? (
            <ScrollableImages photos={photos} />
          ) : (
            <Mosaic photos={photos} />
          )}
        </Container>
      </Section>
    </BackgroundContainer>
  )
}
