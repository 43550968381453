import React from 'react'
import styled from '@emotion/styled'
import ScrollableAnchor from 'react-scrollable-anchor'
import Container from '../../Shared/Container/Container'
import BackgroundContainer from '../../Shared/BackgroundContainer/BackgroundContainer'
import SlidingBanner from '../../Shared/SlidingBanner'
import Title from '../../Shared/TitleWithStyledPeriod/TitleWithStyledPeriod'
import RecruiterBoxListings from './RecruiterBoxListings'
import Steps from './Steps'

const Section = styled('div')`
  padding-top: 121px;
  padding-bottom: 80px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 93px;
    padding-bottom: 20px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 60px;
    padding-bottom: 40px;
  }
`

const StyledTitle = styled(Title)`
  max-width: 506px;
`

export default function JobPosts({
  applicationSteps,
  departments = [],
  title,
  bannerText = '',
  bannerCtaText = '',
  bannerHref = '',
  generalPostMessage = '',
  generalPostCtaText = '',
  generalPostApplicationHref = '',
}) {
  return (
    <BackgroundContainer backgroundColor="#000">
      <Section>
        <Container>
          <StyledTitle textColor="#fff">{title}</StyledTitle>
          <Steps applicationSteps={applicationSteps} />
        </Container>
        <SlidingBanner
          href={bannerHref}
          gastbyLink={false}
          text={bannerText}
          ctaText={bannerCtaText}
        />
        <ScrollableAnchor id="jobs">
          <div />
        </ScrollableAnchor>
        <Container>
          <RecruiterBoxListings
            departments={departments}
            generalPostMessage={generalPostMessage}
            generalPostCtaText={generalPostCtaText}
            generalPostApplicationHref={generalPostApplicationHref}
          />
        </Container>
      </Section>
    </BackgroundContainer>
  )
}
