import React, { useState } from 'react'
import styled from '@emotion/styled'
import Collapse from '@kunukn/react-collapse'
import CollapseIcon from '../../../images/accordion/collapse.svg'
import ExpandIcon from '../../../images/accordion/expand.svg'
import '../../../styles/react-collapse.css'

const AccordionTitle = styled('div')`
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${props => props.color};
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-family: ${props => props.theme.fonts.sourceCode};
  padding-top: 10px;
  padding-bottom: 10px;

  span {
    margin-right: 6px;
  }
`

const Content = styled('p')`
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  margin-top: 0;
  margin-bottom: 15px;
  padding-right: 15px;
`

const Panel = styled('div')`
  padding-left: 15px;

  & + ${() => Panel} {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
`

const AccordionContainer = styled('div')`
  margin-top: 50px;
  margin-left: -15px;
  margin-right: -15px;
`

export default function Accordion({ values }) {
  const [activeIndex, setActiveIndex] = useState(0)

  const onToggle = index => {
    if (activeIndex === index) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index)
    }
  }

  return (
    <AccordionContainer>
      {values.map((value, i) => (
        <Panel key={i}>
          <AccordionTitle
            onClick={() => onToggle(i)}
            isOpen={activeIndex === i}
            color={value.color}
          >
            <span>{value.name}</span>{' '}
            <img src={activeIndex === i ? CollapseIcon : ExpandIcon} />
          </AccordionTitle>
          <Collapse
            className="collapse-css-transition"
            isOpen={activeIndex === i}
          >
            <Content>{value.description}</Content>
          </Collapse>
        </Panel>
      ))}
    </AccordionContainer>
  )
}
