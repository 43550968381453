import React from 'react'
import styled from '@emotion/styled'

const PerkContainer = styled('div')`
  flex-basis: 30%;
  margin-top: 20px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    flex-basis: 40%;
    margin-top: 0;
  }
`

const PerkTitle = styled('h4')`
  font-family: ${props => props.theme.fonts.sourceCode};
  font-size: 24px;
  line-height: 34px;
  text-transform: uppercase;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 14px;
    line-height: 18px;
  }
`

const PerkText = styled('p')`
  font-size: 24px;
  line-height: 27px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 6px;
    font-size: 18px;
    line-height: 20px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
  }
`

const ImageContainer = styled('div')`
  height: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-bottom: 0;
  }
`

export default function Perk({ perk }) {
  return (
    <PerkContainer>
      <ImageContainer>
        <img src={perk.image} alt="" />
      </ImageContainer>
      <PerkTitle>{perk.title}</PerkTitle>
      <PerkText>{perk.description}</PerkText>
    </PerkContainer>
  )
}
