import React from 'react'
import styled from '@emotion/styled'

const ValueName = styled('h3')`
  color: ${props => props.color};
  text-transform: uppercase;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 1px;
  font-family: ${props => props.theme.fonts.sourceCode};

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 18px;
    line-height: 23px;
  }
`

const ValuesContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  margin: 100px auto 0;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 112px;
  }
`

const ValueContainer = styled('div')`
  width: 45%;
`

const ValueDescription = styled('p')`
  font-size: 24px;
  line-height: 24px;
  color: #fff;
  padding-right: 70px;
  margin-top: 10px;
  margin-bottom: 36px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 20px;
    line-height: 22px;
    margin-top: 8px;
    padding-right: 32px;
  }
`

export default function ValuesList({ values }) {
  return (
    <ValuesContainer>
      {values.map((value, i) => (
        <ValueContainer key={i}>
          <ValueName color={value.color}>{value.name}</ValueName>
          <ValueDescription>{value.description}</ValueDescription>
        </ValueContainer>
      ))}
    </ValuesContainer>
  )
}
