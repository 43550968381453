import React from 'react'
import styled from '@emotion/styled'

const ScrollContainer = styled('div')`
  display: flex;
  overflow: scroll;
  margin-left: -65px;
  margin-right: -65px;
`

const Photo = styled('img')`
  height: 151px;
  width: auto;

  & + ${() => Photo} {
    margin-left: 14px;
  }
`

export default function ScrollableImages({ photos }) {
  return (
    <ScrollContainer>
      {photos.map((photo, i) => (
        <Photo src={photo} key={i} alt="" />
      ))}
    </ScrollContainer>
  )
}
