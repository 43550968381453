import React from 'react'
import styled from '@emotion/styled'
import useWindowSize from '../../../util/useWindowSize'
import Container from '../../Shared/Container/Container'
import BackgroundContainer from '../../Shared/BackgroundContainer/BackgroundContainer'
import Title from '../../Shared/TitleWithStyledPeriod/TitleWithStyledPeriod'
import Accordion from './Accordion'
import ValuesList from './ValuesList'
import BackgroundImage from '../../Shared/BackgroundImage/BackgroundImage'
import BackgroundDesktop from '../../../images/cl-outline-red.svg'
import BackgroundTablet from '../../../images/cl-outline-red-tablet.svg'
import BackgroundMobile from '../../../images/cl-outline-red-mobile.svg'

const Section = styled('div')`
  padding-top: 126px;
  padding-bottom: 86px;
  position: relative;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 81px;
    padding-bottom: 81px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 60px;
    padding-bottom: 91px;
  }
`

const TitleContainer = styled('div')`
  max-width: 557px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 450px;
  }
`

const Text = styled('p')`
  font-size: 30px;
  line-height: 34px;
  color: #fff;
  font-weight: normal;
  max-width: 586px;
  display: flex;
  margin-left: auto;
  margin-top: 109px;
  font-family: ${props => props.theme.fonts.secondaryBook};

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 24px;
    line-height: 27px;
    max-width: 320px;
    margin-top: 65px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 18px;
    line-height: 20px;
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute;
  right: 0;
  top: 12px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    top: 41px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    top: 121px;
  }
`

export default function Values({ values = [], title, subtitle }) {
  const screenSize = useWindowSize()
  const ValuesContainerDesktop = React.useCallback(
    () => <ValuesList values={values} />,
    [values]
  )

  const MobileAccordion = React.useCallback(
    () => <Accordion values={values} />,
    [values]
  )

  return (
    <BackgroundContainer backgroundColor="#000">
      <Section>
        <StyledBackgroundImage
          desktop={BackgroundDesktop}
          tablet={BackgroundTablet}
          mobile={BackgroundMobile}
        />
        <Container>
          <TitleContainer>
            <Title textColor="#fff">{title}</Title>
          </TitleContainer>
          <Text>{subtitle}</Text>
          {screenSize === 'mobile' ? (
            <MobileAccordion />
          ) : (
            <ValuesContainerDesktop />
          )}
        </Container>
      </Section>
    </BackgroundContainer>
  )
}
