import React from 'react'
import styled from '@emotion/styled'
import Map from './Map'
import Container from '../../Shared/Container/Container'
import Title from '../../Shared/TitleWithStyledPeriod/TitleWithStyledPeriod'
import BackgroundImage from '../../Shared/BackgroundImage/BackgroundImage'
import BackgroundContainer from '../../Shared/BackgroundContainer/BackgroundContainer'
import BackgroundDesktop from '../../../images/crow-outline-grey-desktop.svg'
import BackgroundTablet from '../../../images/crow-outline-grey-tablet.svg'
import BackgroundMobile from '../../../images/crow-outline-grey-mobile.svg'

const Section = styled('div')`
  padding-top: 122px;
  padding-bottom: 130px;
  position: relative;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 93px;
    padding-bottom: 85px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 60px;
    padding-bottom: 80px;
  }
`

const StyledTitle = styled(Title)`
  max-width: 515px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 415px;
  }
`

const Desription = styled('p')`
  font-size: 30px;
  line-height: 34px;
  max-width: 558px;
  display: flex;
  margin-left: auto;
  font-weight: normal;
  margin-top: 170px;
  font-family: ${props => props.theme.fonts.secondaryBook};

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 304px;
    font-size: 24px;
    line-height: 27px;
    margin-top: 75px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 18px;
    line-height: 20px;
    margin-top: 81px;
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute;
  top: 66px;
  right: 0;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    top: 62px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    top: 93px;
  }
`

export default function LocationMap({ title, subtitle }) {
  return (
    <BackgroundContainer backgroundColor="#fff">
      <Section>
        <StyledBackgroundImage
          desktop={BackgroundDesktop}
          tablet={BackgroundTablet}
          mobile={BackgroundMobile}
        />
        <Container>
          <StyledTitle>{title}</StyledTitle>
          <Desription>{subtitle}</Desription>
          <Map />
        </Container>
      </Section>
    </BackgroundContainer>
  )
}
