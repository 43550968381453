import Perk1 from '../../../images/careers/perk1.svg'
import Perk2 from '../../../images/careers/perk2.svg'
import Perk3 from '../../../images/careers/perk3.svg'
import Perk4 from '../../../images/careers/perk4.svg'
import Perk5 from '../../../images/careers/perk5.svg'
import Perk6 from '../../../images/careers/perk6.svg'

export const perks = [
  {
    image: Perk1,
    title: 'Five weeks of paid vacation',
    description:
      'We expect you to enjoy lots of time off, doing the things you love with the people who matter to you.',
  },
  {
    image: Perk2,
    title: 'Motivated team',
    description:
      'Surround yourself with peers who will lift you when you fall and help you reach new heights. It might be a race, but we’re all running together.',
  },
  {
    image: Perk3,
    title: 'Flexible hours',
    description:
      'Whether you’re feeling ill or you just want to skip the commute, everyone has the opportunity to work from home once a week.',
  },
  {
    image: Perk4,
    title: 'Dog friends',
    description:
      'We have a Samoyed named Kara and a scaredy-cat named Panko. They like belly rubs ‘if you can catch Panko.’ Come by and meet them!',
  },
  {
    image: Perk5,
    title: 'Investing in our people',
    description:
      'Nothing makes us happier than helping you reach your personal and professional goals. We are here to support you.',
  },
  {
    image: Perk6,
    title: 'Impactful work',
    description:
      'Work on products that are impacting millions of people across the world. See your hard work get enjoyed by people you know.',
  },
]
