import React from 'react'
import Hero from './Hero'
import HeroWithSVG from './Hero/HeroWithSVG'
import WhoSection from './WhoSection'
import Values from './Values'
import FriendshipPhotos from './FriendshipPhotos'
import Perks from './Perks'
import JobPosts from './JobPosts'
import LocationMap from './LocationMap'
import Line from '../Shared/Line/Line'

export default function Careers(props) {
  const {
    careersData: {
      heroImage,
      heroTitle,
      heroText: { heroText },
      heroCTAText,
      whoTitle,
      whoText,
      whoCTAText,
      whoPeopleImages,
      whoMainImage,
      valuesTitle,
      valuesSubtitle,
      values,
      friendshipPhotosTitle,
      friendshipPhotosSubtitle,
      perksTitle,
      perks,
      locationTitle,
      locationSubtitle,
      jobsTitle,
      applicationSteps,
      departmentColors,
      bannerText,
      bannerCtaText,
      bannerHref,
      generalPostMessage,
      generalPostCtaText,
      generalPostApplicationHref,
    },
  } = props
  return (
    <React.Fragment>
      <HeroWithSVG heroImage={heroImage} />
      <Hero
        heroTitle={heroTitle}
        heroText={heroText}
        heroCTAText={heroCTAText}
      />
      <WhoSection
        whoTitle={whoTitle}
        whoText={whoText}
        whoCTAText={whoCTAText}
        whoPeopleImages={whoPeopleImages}
        whoMainImage={whoMainImage}
      />
      <Values title={valuesTitle} subtitle={valuesSubtitle} values={values} />
      <FriendshipPhotos
        title={friendshipPhotosTitle}
        subtitle={friendshipPhotosSubtitle}
      />
      <Perks title={perksTitle} perks={perks} />
      <LocationMap title={locationTitle} subtitle={locationSubtitle} />
      <JobPosts
        title={jobsTitle}
        applicationSteps={applicationSteps}
        departments={departmentColors}
        bannerText={bannerText}
        bannerCtaText={bannerCtaText}
        bannerHref={bannerHref}
        generalPostMessage={generalPostMessage}
        generalPostCtaText={generalPostCtaText}
        generalPostApplicationHref={generalPostApplicationHref}
      />
      <Line />
    </React.Fragment>
  )
}
