import React from 'react'
import styled from '@emotion/styled'
import Carousel from 'nuka-carousel'
import Perk from './Perk'

const CarouselWrapper = styled('div')`
  margin-top: 30px;
  margin-left: -65px;
  margin-right: -65px;

  .slider-slide {
    padding-left: 65px;
    padding-right: 65px;
  }

  .slider-control-bottomcenter {
    bottom: -50px !important;
    .paging-item {
      .paging-dot {
        background-color: #d8d8d8 !important;
        height: 10px !important;
        width: 10px !important;
        border-radius: 50%;
        transition: 0.3s all ease;

        > circle {
          fill: transparent !important;
        }
      }

      &.active .paging-dot {
        background-color: #979797 !important;
      }

      button {
        opacity: 1 !important;
      }
    }
  }
`

export default function PerksCarousel({ perks }) {
  const perksPerSlide = 3
  const carouselSlides = [...Array(Math.ceil(perks.length / perksPerSlide))]
  const perkSlides = carouselSlides.map((slide, i) =>
    perks.slice(i * perksPerSlide, i * perksPerSlide + perksPerSlide)
  )

  return (
    <CarouselWrapper>
      <Carousel
        // hide prev and next arrows
        renderCenterLeftControls={() => undefined}
        renderCenterRightControls={() => undefined}
        edgeEasing="easeExpOut"
      >
        {perkSlides.map((slide, i) => (
          <div key={i}>
            {slide.map((perk, y) => (
              <Perk key={y} perk={perk} />
            ))}
          </div>
        ))}
      </Carousel>
    </CarouselWrapper>
  )
}
