import React from 'react'
import styled from '@emotion/styled'
import Container from '../../Shared/Container/Container'
import { CTA } from '../../Buttons/TransparentCTA'
import Title from '../../Shared/TitleWithStyledPeriod/TitleWithStyledPeriod'
import Headshots from './Headshots'
import BackgroundImage from '../../Shared/BackgroundImage/BackgroundImage'
import BackgroundContainer from '../../Shared/BackgroundContainer/BackgroundContainer'
import BackgroundDesktop from '../../../images/cl-outline-white2.svg'
import BackgroundTablet from '../../../images/cl-outline-white-tablet.svg'
import BackgroundMobile from '../../../images/cl-outline-grey2.svg'

const Section = styled('div')`
  position: relative;
  padding-top: 117px;
  padding-bottom: 78px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 80px;
    padding-bottom: 90px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

const CTAContainer = styled('div')`
  margin: 55px auto 0;
  max-width: 240px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 50px;
    max-width: 190px;
  }
`

const StyledCTA = styled(CTA)`
  span {
    font-family: ${props => props.theme.fonts.primaryMedium};
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    height: 40px;
    line-height: 40px;

    span {
      font-size: 14px;
      line-height: 16px;
    }
  }
`

const Text = styled('p')`
  max-width: 586px;
  font-size: 30px;
  font-weight: normal;
  line-height: 34px;
  font-family: ${props => props.theme.fonts.secondaryBook};

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 24px;
    line-height: 27px;
    padding-left: 15px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 18px;
    line-height: 20px;
  }
`

const TitleContainer = styled('div')`
  max-width: 488px;
  width: 100%;
  margin-right: 30px;
`

const TextContainer = styled('div')`
  padding-top: 100px;
  margin-bottom: 30px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 50%;
    padding-top: 85px;
    margin-left: auto;
    margin-bottom: 50px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: unset;
    margin-bottom: 36px;
  }
`

const ContentContainer = styled('div')`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    display: block;
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute;
  left: 0;
  top: 60px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    top: 80px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    top: 172px;
  }
`

export default function WhoSection(props) {
  const {
    whoTitle,
    whoText: { whoText },
    whoCTAText,
    whoPeopleImages,
    whoMainImage,
  } = props

  return (
    <BackgroundContainer backgroundColor="#e9e9e9">
      <Section>
        <StyledBackgroundImage
          desktop={BackgroundDesktop}
          tablet={BackgroundTablet}
          mobile={BackgroundMobile}
        />
        <Container>
          <ContentContainer>
            <TitleContainer>
              <Title>{whoTitle}</Title>
            </TitleContainer>
            <TextContainer>
              <Text>{whoText}</Text>
            </TextContainer>
          </ContentContainer>
          <Headshots
            whoPeopleImages={whoPeopleImages}
            whoMainImage={whoMainImage}
          />
          <CTAContainer>
            <StyledCTA to="/who-we-are" blackBorder>
              <span>{whoCTAText}</span>
            </StyledCTA>
          </CTAContainer>
        </Container>
      </Section>
    </BackgroundContainer>
  )
}
