import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { officeLocation, locations } from './data'
import LocationCarousel from './LocationCarousel'
import useWindowSize from '../../../util/useWindowSize'
import map from '../../../images/careers/map/map.jpg'
import map2x from '../../../images/careers/map/map@2x.jpg'
import arrow from '../../../images/careers/map/speech-arrow.svg'

const MapContainer = styled('div')`
  position: relative;
  margin-left: 9%;
  margin-right: 9%;
  margin-top: 124px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 72px;
    margin-left: 0;
    margin-right: 0;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-left: -45px;
    margin-right: -45px;
    margin-top: 50px;
  }
`

const MapImage = styled('img')`
  width: 100%;
`

const Pin = styled('img')`
  vertical-align: bottom;
  pointer-events: all;
  transition: 0.3s;
  transform-origin: bottom center;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 13px;
    ${props =>
      props.active &&
      css`
        transform: scale(1.5);
      `}
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: 7px;
    ${props =>
      props.active &&
      css`
        transform: scale(2.5);
      `}
  }
`

const LocationName = styled('h4')`
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  margin: 0;
  margin-bottom: 2px;
  text-transform: uppercase;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 10px;
    line-height: 11px;
  }
`

const DescriptionP = styled('p')`
  font-family: ${props => props.theme.fonts.sourceCode};
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  margin: 0;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 9px;
    line-height: 12px;
  }
`

const DescriptionContainer = styled('div')`
  background: #fff;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px 10px 10px 0;
  padding: 11px 8px 11px 24px;
  transition: 0.15s;
  pointer-events: none;
  z-index: 1;
  position: relative;
  display: inline-block;
  max-width: 233px;
  left: -4%;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 7px 5px 7px 15px;
    max-width: 150px;
  }

  ${props =>
    props.imagePosition === 'upper-right' &&
    css`
      border-radius: 10px 10px 0 10px;
      padding: 11px 40px 11px 8px;
      left: unset;
      right: -8%;
      top: -39px;

      @media screen and (max-width: ${props.theme.screenWidth.large}) {
        top: -30px;
        padding: 7px 15px 7px 5px;
      }
    `};

  ${props =>
    props.imagePosition === 'bottom-left' &&
    css`
      left: -5%;
      top: 25px;
    `};

  ${props =>
    props.imagePosition === 'bottom-right' &&
    css`
      border-radius: 10px 10px 0 10px;
      padding: 11px 40px 11px 8px;
      left: unset;
      right: -12%;
      top: 24px;

      @media screen and (max-width: ${props.theme.screenWidth.large}) {
        padding: 7px 15px 7px 5px;
        right: -7%;
      }
    `};
`

const OfficeLabel = styled(DescriptionContainer)`
  background: #000;
  border-radius: 5px 5px 5px 0;
  padding: 8px;
  top: -40px;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.15);
  left: 0;

  p {
    color: #fff;
    font-size: 18px;
    line-height: 23px;
    margin: 0;
    text-transform: uppercase;
    font-family: ${props => props.theme.fonts.sourceCode};
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 5px;
    box-shadow: 0.644017px 0 6.44017px rgba(0, 0, 0, 0.15);
    top: -20px;

    p {
      font-size: 12px;
      line-height: 15px;
    }
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 1px 3px 2px;
    margin: 0;
    top: -5px;

    p {
      font-size: 9px;
      line-height: 11px;
    }
  }
`

const MobileDescription = styled('div')`
  background: #fff;
  font-size: 11px;
  line-height: 14px;
  padding: 4px 10px 7px 10px;
  opacity: ${props => (props.active ? 1 : 0)};
  position: absolute;
  width: 137px;
  border-radius: 6px;
  box-shadow: 0.644017px 0 6.44017px rgba(0, 0, 0, 0.15);
  bottom: 150%;
  right: ${props => (props.reversed ? 'auto' : '50%')};
  left: ${props => (props.reversed ? '50%' : 'auto')};

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    width: 100px;
  }

  &::after {
    content: url(${arrow});
    filter: drop-shadow(5px 15px 6.44017px rgba(0, 0, 0, 0.15));
    display: block;
    position: absolute;
    bottom: 0%;
    transform: translateY(80%) ${props => props.reversed && 'scale(-1, 1)'};
    opacity: ${props => (props.active ? 1 : 0)};
    right: ${props => (props.reversed ? 'auto' : 0)};
    left: ${props => (props.reversed ? '0' : 'auto')};
  }
`

const PinContainer = styled('div')`
  position: absolute;
  transform: translate(${props => (props.office ? '0' : '-50%')}, -100%);
  left: ${props => props.x}%;
  top: ${props => props.y}%;
  pointer-events: none;
  z-index: ${props => (props.active ? 20 : 1)};

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    transform: translateY(${props => (props.office ? '-80%' : '-60%')});
  }
`

const LocationContainer = styled('div')`
  opacity: ${props => (props.displayContainer ? 1 : 0)};
  transition: 0.15s;
  display: flex;
  align-items: center;
  ${props =>
    props.imagePosition === 'upper-right' &&
    css`
      flex-direction: row-reverse;
    `};
  ${props =>
    props.imagePosition === 'bottom-right' &&
    css`
      flex-direction: row-reverse;
    `};
`

const LocationImage = styled('img')`
  position: relative;
  z-index: 2;
  width: 258px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    width: 160px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    width: 81px;
  }
`

const LocationWrapper = styled('div')`
  position: absolute;
  pointer-events: none;
  z-index: 10;

  ${props =>
    props.imagePosition === 'upper-left' &&
    css`
      top: -47px;
      left: -23px;
    `};
  ${props =>
    props.imagePosition === 'upper-right' &&
    css`
      top: -47px;
      right: 40px;
    `};
  ${props =>
    props.imagePosition === 'bottom-left' &&
    css`
      bottom: -35px;
      left: -25px;
    `};
  ${props =>
    props.imagePosition === 'bottom-right' &&
    css`
      bottom: 72px;
      right: 45px;
    `};
`

const SVG = styled('svg')`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`

const Line = styled('line')`
  stroke: ${props => props.lineColor};
  stroke-width: 3px;
  stroke-dasharray: 7, 8;
  stroke-linecap: round;
  transform: translateY(-10px);
`

const DashedLine = ({ currentLocation }) => {
  const startPoint = currentLocation.newCoordinates || [0, 0]
  let endPoint = [0, 0]
  switch (currentLocation.imagePosition) {
    case 'upper-left':
      endPoint = ['12%', '17%']
      break
    case 'upper-right':
      endPoint = ['85%', '20%']
      break
    case 'bottom-left':
      endPoint = ['12%', '85%']
      break
    case 'bottom-right':
      endPoint = ['83%', '70%']
      break
  }

  return (
    <SVG>
      <Line
        x1={`${startPoint[0]}%`}
        y1={`${startPoint[1]}%`}
        x2={endPoint[0]}
        y2={endPoint[1]}
        lineColor={currentLocation.color}
      />
    </SVG>
  )
}

const OfficeLocation = ({ coordinates, icon, name }) => (
  <PinContainer x={coordinates[0]} y={coordinates[1]} office={true}>
    <Pin src={icon} alt={name} />
    <OfficeLabel>
      <p>{name}</p>
    </OfficeLabel>
  </PinContainer>
)

const MapPin = props => {
  const {
    name,
    icon,
    coordinates,
    handleMapLocationHover,
    clearCurrentLocation,
    isMobile,
    description,
    currentLocation,
  } = props

  return (
    <PinContainer
      x={coordinates[0]}
      y={coordinates[1]}
      onMouseEnter={!isMobile ? handleMapLocationHover : undefined}
      onMouseLeave={!isMobile ? clearCurrentLocation : undefined}
      active={name === currentLocation.name}
    >
      <Pin src={icon} alt={name} active={name === currentLocation.name} />
      {isMobile && (
        <MobileDescription
          reversed={currentLocation.mobileReversedDescription}
          active={name === currentLocation.name}
        >
          <LocationName>{name}</LocationName>
          <DescriptionP>{description}</DescriptionP>
        </MobileDescription>
      )}
    </PinContainer>
  )
}

const LocationHoverDetails = ({ currentLocation }) => {
  const { description, image, image2x, imagePosition, name } = currentLocation

  return (
    <LocationWrapper imagePosition={imagePosition}>
      <LocationContainer imagePosition={imagePosition} displayContainer={name}>
        {image && <LocationImage src={image} srcset={`${image2x} 2x`} />}
        {description && (
          <DescriptionContainer imagePosition={imagePosition}>
            <LocationName>{name}</LocationName>
            <DescriptionP>{description}</DescriptionP>
          </DescriptionContainer>
        )}
      </LocationContainer>
    </LocationWrapper>
  )
}

const fullMapWidth = 988
const fullMapHeight = 524
const defaultPinWidths = [7, 13, 22]
const defaultPinHeights = [10, 19, 30]
const officePinWidths = [9, 18, 28]
const officePinHeights = [13, 26, 40]

const calculateCoordinates = (coords, screenSize, office) => {
  const pinWidths = office ? officePinWidths : defaultPinWidths
  const pinHeights = office ? officePinHeights : defaultPinHeights

  let pinWidth = pinWidths[0]
  let pinHeight = pinHeights[0]

  switch (screenSize) {
    case 'mobile':
      pinWidth = pinWidths[0]
      pinHeight = pinHeights[0]
      break
    case 'tablet':
      pinWidth = pinWidths[1]
      pinHeight = pinHeights[1]
      break
    default:
      pinWidth = pinWidths[2]
      pinHeight = pinHeights[2]
      break
  }

  const x = coords[0]
  const y = coords[1]

  const newX = ((x + pinWidth / 2) / fullMapWidth) * 100
  const newY = ((y + pinHeight) / fullMapHeight) * 100
  const newCoords = [newX, newY]

  return newCoords
}

export default function Map() {
  const screenSize = useWindowSize()
  const isMobile = screenSize !== 'desktop'
  const [currentLocation, setCurrentLocation] = useState({})

  const handleMapLocationHover = (location, newCoordinates) => {
    location.newCoordinates = newCoordinates
    setCurrentLocation(location)
  }

  const clearCurrentLocation = () => {
    setCurrentLocation({})
  }

  const newOfficeCoordinates = calculateCoordinates(
    officeLocation.coordinates,
    screenSize,
    true
  )

  return (
    <div>
      <MapContainer>
        <MapImage src={map} srcSet={`${map2x} 2x`} alt="" />
        {locations.map((location, i) => {
          const newCoordinates = calculateCoordinates(
            location.coordinates,
            screenSize
          )
          return (
            <MapPin
              key={i}
              name={location.name}
              icon={location.icon}
              coordinates={newCoordinates}
              description={location.description}
              handleMapLocationHover={() =>
                handleMapLocationHover(location, newCoordinates)
              }
              clearCurrentLocation={clearCurrentLocation}
              isMobile={isMobile}
              currentLocation={currentLocation}
            />
          )
        })}
        {!isMobile && <DashedLine currentLocation={currentLocation} />}
        <OfficeLocation
          name={officeLocation.name}
          icon={officeLocation.icon}
          coordinates={newOfficeCoordinates}
        />
        {!isMobile && (
          <LocationHoverDetails currentLocation={currentLocation} />
        )}
      </MapContainer>
      {isMobile && (
        <LocationCarousel
          currentLocation={currentLocation}
          setCurrentLocation={setCurrentLocation}
        />
      )}
    </div>
  )
}
