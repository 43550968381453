import pinBar from '../../../images/careers/map/pin-bar.svg'
import pinBbt from '../../../images/careers/map/pin-bbt.svg'
import pinCoffee from '../../../images/careers/map/pin-coffee.svg'
import pinCookie from '../../../images/careers/map/pin-cookie.svg'
import pinOffice from '../../../images/careers/map/pin-office.svg'
import pinRestaurant from '../../../images/careers/map/pin-restaurant.svg'
import coco from '../../../images/careers/map/coco.png'
import coco2x from '../../../images/careers/map/coco@2x.png'
import freshii from '../../../images/careers/map/freshii.png'
import freshii2x from '../../../images/careers/map/freshii@2x.png'
import leGourmand from '../../../images/careers/map/le-gourmand.png'
import leGourmand2x from '../../../images/careers/map/le-gourmand@2x.png'
import wideOpen from '../../../images/careers/map/wide-open.png'
import wideOpen2x from '../../../images/careers/map/wide-open@2x.png'
import theHaam from '../../../images/careers/map/haam.png'
import theHaam2x from '../../../images/careers/map/haam@2x.png'
import khaoSanRoad from '../../../images/careers/map/khao-san-road.png'
import khaoSanRoad2x from '../../../images/careers/map/khao-san-road@2x.png'
import kupfertKim from '../../../images/careers/map/kupfertkim.png'
import kupfertKim2x from '../../../images/careers/map/kupfertkim@2x.png'
import quantumCoffee from '../../../images/careers/map/quantum-coffee.png'
import quantumCoffee2x from '../../../images/careers/map/quantum-coffee@2x.png'
import whatABagel from '../../../images/careers/map/what-a-bagel.png'
import whatABagel2x from '../../../images/careers/map/what-a-bagel@2x.png'
import bostonPizza from '../../../images/careers/map/boston-pizza.png'
import bostonPizza2x from '../../../images/careers/map/boston-pizza@2x.png'
import paramount from '../../../images/careers/map/paramount.png'
import paramount2x from '../../../images/careers/map/paramount@2x.png'
import strangeLove from '../../../images/careers/map/strange-love.png'
import strangeLove2x from '../../../images/careers/map/strange-love@2x.png'
import myRotiPlace from '../../../images/careers/map/my-roti.png'
import myRotiPlace2x from '../../../images/careers/map/my-roti@2x.png'
import cjSushi from '../../../images/careers/map/cj-sushi.png'
import cjSushi2x from '../../../images/careers/map/cj-sushi@2x.png'
import pizzaiolo from '../../../images/careers/map/pizzaiolo.png'
import pizzaiolo2x from '../../../images/careers/map/pizzaiolo@2x.png'
import wilbur from '../../../images/careers/map/wilbur.png'
import wilbur2x from '../../../images/careers/map/wilbur@2x.png'

export const colors = {
  purple: '#4F2C8D',
  blue: '#26A2D8',
  grey: '#2F4B64',
}

export const officeLocation = {
  name: 'Our Office',
  icon: pinOffice,
  coordinates: [373, 290],
}

export const quadrants = [
  'upper-left',
  'upper-right',
  'bottom-left',
  'bottom-right',
]

export const locations = [
  {
    name: 'Le Gourmand',
    description: 'Our favorite chocolate chip cookies',
    image: leGourmand,
    image2x: leGourmand2x,
    imagePosition: 'upper-left',
    icon: pinCookie,
    color: colors.purple,
    coordinates: [317, 225],
    mobileReversedDescription: false,
  },
  {
    name: 'Freshii',
    description: 'When you’re in a healthy mood',
    image: freshii,
    image2x: freshii2x,
    imagePosition: 'upper-left',
    icon: pinRestaurant,
    color: colors.blue,
    coordinates: [300, 265],
    mobileReversedDescription: true,
  },
  {
    name: 'Coco Bubble Tea',
    description: 'Youʼre buying right?',
    image: coco,
    image2x: coco2x,
    imagePosition: 'upper-left',
    icon: pinBbt,
    color: colors.purple,
    coordinates: [400, 150],
    mobileReversedDescription: false,
  },
  {
    name: 'CJ Lunchbox',
    description: 'Sushi on a budget',
    image: cjSushi,
    image2x: cjSushi2x,
    imagePosition: 'upper-left',
    icon: pinRestaurant,
    color: colors.blue,
    coordinates: [381, 238],
    mobileReversedDescription: false,
  },
  {
    name: 'Wilbur',
    description: 'See you Friday',
    image: wilbur,
    image2x: wilbur2x,
    imagePosition: 'upper-left',
    icon: pinRestaurant,
    color: colors.blue,
    coordinates: [189, 367],
    mobileReversedDescription: true,
  },
  {
    name: 'The Haam',
    description: 'Tacos or sushi - theyʼve mastered both, surprisingly!',
    image: theHaam,
    image2x: theHaam2x,
    imagePosition: 'upper-right',
    icon: pinBar,
    color: colors.grey,
    coordinates: [618, 231],
    mobileReversedDescription: false,
  },
  {
    name: 'Kupfert & Kim',
    description: 'Great healthy option for non-meat eaters',
    image: kupfertKim,
    image2x: kupfertKim2x,
    imagePosition: 'upper-right',
    icon: pinRestaurant,
    color: colors.blue,
    coordinates: [328, 270],
    mobileReversedDescription: false,
  },
  {
    name: 'Khao San Road',
    description: 'Our favorite lunch spot for Thai',
    image: khaoSanRoad,
    image2x: khaoSanRoad2x,
    imagePosition: 'upper-right',
    icon: pinRestaurant,
    color: colors.blue,
    coordinates: [503, 295],
    mobileReversedDescription: false,
  },
  {
    name: 'What a Bagel',
    description: 'You gotta get the sweet potato fries',
    image: whatABagel,
    image2x: whatABagel2x,
    imagePosition: 'bottom-left',
    icon: pinRestaurant,
    color: colors.blue,
    coordinates: [341, 347],
    mobileReversedDescription: false,
  },
  {
    name: 'Strange Love',
    description: 'Most unique drinks in the area',
    image: strangeLove,
    image2x: strangeLove2x,
    imagePosition: 'bottom-right',
    icon: pinCoffee,
    color: colors.purple,
    coordinates: [382, 340],
    mobileReversedDescription: false,
  },
  {
    name: 'Quantum Coffee',
    description: 'Great pour over coffee and espresso',
    image: quantumCoffee,
    image2x: quantumCoffee2x,
    imagePosition: 'bottom-left',
    icon: pinCoffee,
    color: colors.purple,
    coordinates: [352, 393],
    mobileReversedDescription: false,
  },
  {
    name: 'Paramount',
    description: 'Double hummus no fries',
    image: paramount,
    image2x: paramount2x,
    imagePosition: 'upper-right',
    icon: pinRestaurant,
    color: colors.blue,
    coordinates: [349, 247],
    mobileReversedDescription: false,
  },
  {
    name: 'Wide Open',
    description: 'Cash only...',
    image: wideOpen,
    image2x: wideOpen2x,
    imagePosition: 'upper-left',
    icon: pinBar,
    color: colors.grey,
    coordinates: [360, 260],
    mobileReversedDescription: false,
  },
  {
    name: 'My Roti Place',
    description: 'Come hungry',
    image: myRotiPlace,
    image2x: myRotiPlace2x,
    imagePosition: 'bottom-left',
    icon: pinRestaurant,
    color: colors.blue,
    coordinates: [285, 206],
    mobileReversedDescription: true,
  },
  {
    name: 'Pizzaiolo',
    description: 'The go to for office events',
    image: pizzaiolo,
    image2x: pizzaiolo2x,
    imagePosition: 'bottom-left',
    icon: pinRestaurant,
    color: colors.blue,
    coordinates: [358, 280],
    mobileReversedDescription: false,
  },
  {
    name: 'Boston Pizza',
    description: 'The best place to catch the game',
    image: bostonPizza,
    image2x: bostonPizza2x,
    imagePosition: 'bottom-right',
    icon: pinRestaurant,
    color: colors.blue,
    coordinates: [684, 468],
    mobileReversedDescription: false,
  },
]
