import React from 'react'
import styled from '@emotion/styled'
import Carousel from 'nuka-carousel'
import useWindowSize from '../../../util/useWindowSize'

const StepNumber = styled('span')`
  color: ${props => props.textColor};
  font-weight: 500;
  font-size: 76px;
  line-height: 66px;
  font-family: ${props => props.theme.fonts.primaryMedium};

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-weight: 500;
    font-size: 48px;
    line-height: 32px;
    align-items: center;
  }
`

const StepTitleContainer = styled('div')`
  display: flex;
`

const StepContainer = styled('div')`
  flex-basis: 213px;

  & + ${() => StepContainer} {
    margin-left: 20px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    flex-basis: 41%;
    min-width: 200px;
    margin-top: 17px;

    & + ${() => StepContainer} {
      margin-left: 0;
    }

    &:nth-of-type(2n) {
      margin-left: 20px;
    }
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    width: 177px;
    min-width: unset;

    &:nth-of-type(2n) {
      margin-left: 0;
    }
  }
`

const StepTitle = styled('h3')`
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 1.33333px;
  color: #fff;
  font-family: ${props => props.theme.fonts.sourceCode};
  text-transform: uppercase;
  margin-left: 10px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 14px;
    line-height: 18px;
    flex-basis: 100px;
    margin-left: 5px;
  }
`

const StepsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 127px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    flex-wrap: wrap;
    padding-left: 65px;
    padding-right: 20px;
    margin-top: 43px;
  }
`

const StepDescription = styled('p')`
  color: #fff;
  font-size: 24px;
  line-height: 27px;
  margin-top: 20px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 18px;
    line-height: 20px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: 8px;
    font-size: 16px;
    line-height: 18px;
  }
`

const CarouselWrapper = styled('div')`
  margin-top: 60px;
  margin-left: -65px;
  margin-right: -65px;

  .slider-slide {
    padding-right: 65px;
  }

  .slider-control-bottomcenter {
    > ul {
      top: 16px !important;
    }

    .paging-item {
      .paging-dot {
        background-color: #979797 !important;
        height: 10px !important;
        width: 10px !important;
        border-radius: 50%;
        transition: 0.3s all ease;

        > circle {
          fill: transparent !important;
        }
      }

      &.active .paging-dot {
        background-color: #d8d8d8 !important;
      }

      button {
        opacity: 1 !important;
        padding: 5px;
      }
    }
  }
`

const DesktopSteps = ({ applicationSteps = [] }) => (
  <StepsContainer>
    {applicationSteps.map((step, i) => (
      <StepContainer key={i}>
        <StepTitleContainer>
          <StepNumber textColor={step.color}>{i + 1}</StepNumber>
          <StepTitle>{step.title}</StepTitle>
        </StepTitleContainer>
        <StepDescription>{step.description}</StepDescription>
      </StepContainer>
    ))}
  </StepsContainer>
)

const MobileSteps = ({ applicationSteps = [] }) => (
  <CarouselWrapper>
    <Carousel
      // hide prev and next arrows
      renderCenterLeftControls={() => undefined}
      renderCenterRightControls={() => undefined}
      edgeEasing="easeExpOut"
      slidesToShow={1.5}
      cellAlign="center"
    >
      {applicationSteps.map((step, i) => (
        <StepContainer key={i}>
          <StepTitleContainer>
            <StepNumber textColor={step.color}>{i + 1}</StepNumber>
            <StepTitle>{step.title}</StepTitle>
          </StepTitleContainer>
          <StepDescription>{step.description}</StepDescription>
        </StepContainer>
      ))}
    </Carousel>
  </CarouselWrapper>
)

export default function Steps({ applicationSteps }) {
  const screenSize = useWindowSize()
  return screenSize === 'mobile' ? (
    <MobileSteps applicationSteps={applicationSteps} />
  ) : (
    <DesktopSteps applicationSteps={applicationSteps} />
  )
}
