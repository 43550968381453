import React from 'react'
import BackgroundContainer from '../../Shared/BackgroundContainer/BackgroundContainer'
import ContentBlock from '../../Shared/Blocks/ContentBlock/ContentBlock'
import BackgroundDesktop from '../../../images/crow-outline-grey-desktop.svg'
import BackgroundTablet from '../../../images/crow-outline-grey-tablet.svg'
import BackgroundMobile from '../../../images/crow-outline-grey-mobile.svg'

export default function Hero(props) {
  const { heroTitle, heroText, heroCTAText } = props
  return (
    <BackgroundContainer backgroundColor="#fff">
      <ContentBlock
        heroTitle={heroTitle}
        heroText={heroText}
        heroCTAText={heroCTAText}
        backgroundDesktop={BackgroundDesktop}
        backgroundTablet={BackgroundTablet}
        backgroundMobile={BackgroundMobile}
        ctaHref="#jobs"
      />
    </BackgroundContainer>
  )
}
