import React from 'react'
import styled from '@emotion/styled'
import useWindowSize from '../../../util/useWindowSize'
import Container from '../../Shared/Container/Container'
import BackgroundContainer from '../../Shared/BackgroundContainer/BackgroundContainer'
import Title from '../../Shared/TitleWithStyledPeriod/TitleWithStyledPeriod'
import PerksList from './PerksList'
import PerksCarousel from './PerksCarousel'
import { perks } from './data'

const Section = styled('div')`
  padding-top: 124px;
  padding-bottom: 155px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 31px;
    padding-bottom: 110px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 60px;
    padding-bottom: 90px;
  }
`

const StyledTitle = styled(Title)`
  max-width: 544px;
`

export default function Perks({ perks = [], title }) {
  const screenSize = useWindowSize()
  return (
    <BackgroundContainer backgroundColor="#e5e5e5">
      <Section>
        <Container>
          <StyledTitle>{title}</StyledTitle>
          {screenSize === 'mobile' ? (
            <PerksCarousel perks={perks} />
          ) : (
            <PerksList perks={perks} />
          )}
        </Container>
      </Section>
    </BackgroundContainer>
  )
}
