import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import {
  Parallax,
  ParallaxBanner,
  ParallaxContext,
} from 'react-scroll-parallax'
import { theme } from '../../../styles/theme'
import useWindowSize from '../../../util/useWindowSize'

const Photo1TabletStyles = {
  paddingLeft: 0,
  marginLeft: -20,
  maxWidth: 395.03,
  height: 296.43,
}

const Photo1ContainerStyles = {
  maxWidth: 622.89,
  flexShrink: 1,
  flexGrow: 1,
  height: 467.42,
  paddingLeft: 20,
  [`@media screen and (maxWidth: ${theme.screenWidth.large})`]: {
    ...Photo1TabletStyles,
  },
}

const Photo1TabletStylesCombined = {
  ...Photo1ContainerStyles,
  ...Photo1TabletStyles,
}

const Photo2TabletStyles = {
  top: 50,
  maxWidth: 150.3,
  height: 199.13,
  marginRight: '25%',
}

const Photo2ContainerStyles = {
  maxWidth: 237,
  flexShrink: 1,
  flexGrow: 1,
  height: 314,
  top: 86,
  left: -20,
  position: 'relative',
  [`@media screen and (maxWidth: ${theme.screenWidth.large})`]: {
    ...Photo2TabletStyles,
  },
}

const Photo2TabletStylesCombined = {
  ...Photo2ContainerStyles,
  ...Photo2TabletStyles,
}

const Photo3TabletStyles = {
  maxWidth: 246.06,
  height: 164.25,
}

const Photo3ContainerStyles = {
  maxWidth: 388,
  flexShrink: 1,
  flexGrow: 1,
  height: 259,
  position: 'relative',
  top: -15,
  left: -60,
  [`@media screen and (maxWidth: ${theme.screenWidth.large})`]: {
    ...Photo3TabletStyles,
  },
}

const Photo3TabletStylesCombined = {
  ...Photo3ContainerStyles,
  ...Photo3TabletStyles,
}

const Photo4TabletStyles = {
  top: 80,
  maxWidth: 110.35,
  height: 164.25,
}

const Photo4ContainerStyles = {
  maxWidth: 174,
  flexShrink: 1,
  flexGrow: 1,
  height: 259,
  position: 'relative',
  top: 176,
  left: -60,
  [`@media screen and (maxWidth: ${theme.screenWidth.large})`]: {
    ...Photo4TabletStyles,
  },
}

const Photo4TabletStylesCombined = {
  ...Photo4ContainerStyles,
  ...Photo4TabletStyles,
}

const Photo5TabletStyles = {
  maxWidth: 164.89,
  height: 164.25,
}

const Photo5ContainerStyles = {
  maxWidth: 260,
  flexShrink: 1,
  flexGrow: 1,
  height: 259,
  position: 'relative',
  top: -15,
  left: -60,
  marginRight: -60,
  [`@media screen and (maxWidth: ${theme.screenWidth.large})`]: {
    ...Photo5TabletStyles,
  },
}

const Photo5TabletStylesCombined = {
  ...Photo5ContainerStyles,
  ...Photo5TabletStyles,
}

const Photo6TabletStyles = {
  top: 30,
  maxWidth: 246.7,
  height: 164.25,
}

const Photo6ContainerStyles = {
  maxWidth: 389,
  flexShrink: 1,
  flexGrow: 1,
  height: 259,
  position: 'relative',
  top: 73,
  [`@media screen and (maxWidth: ${theme.screenWidth.large})`]: {
    ...Photo6TabletStyles,
  },
}

const Photo6TabletStylesCombined = {
  ...Photo6ContainerStyles,
  ...Photo6TabletStyles,
}

const Photo7TabletStyles = {
  top: 20,
  left: 0,
  maxWidth: 246.7,
  height: 164.25,
}

const Photo7ContainerStyles = {
  maxWidth: 389,
  flexShrink: 1,
  flexGrow: 1,
  height: 259,
  position: 'relative',
  top: 145,
  left: 50,
  [`@media screen and (maxWidth: ${theme.screenWidth.large})`]: {
    ...Photo7TabletStyles,
  },
}

const Photo7TabletStylesCombined = {
  ...Photo7ContainerStyles,
  ...Photo7TabletStyles,
}

const Photo8TabletStyles = {
  top: 100,
  left: 0,
  maxWidth: 164.33,
  height: 164.33,
}

const Photo8ContainerStyles = {
  maxWidth: 259.12,
  flexShrink: 1,
  flexGrow: 1,
  height: 259.12,
  position: 'relative',
  top: 250,
  left: 50,
  [`@media screen and (maxWidth: ${theme.screenWidth.large})`]: {
    ...Photo8TabletStyles,
  },
}

const Photo8TabletStylesCombined = {
  ...Photo8ContainerStyles,
  ...Photo8TabletStyles,
}

const Photo9TabletStyles = {
  top: 10,
  left: 0,
  maxWidth: 246.7,
  height: 164.25,
}

const Photo9ContainerStyles = {
  maxWidth: 389,
  flexShrink: 1,
  flexGrow: 1,
  height: 259,
  position: 'relative',
  top: 50,
  left: 50,
  [`@media screen and (maxWidth: ${theme.screenWidth.large})`]: {
    ...Photo9TabletStyles,
  },
}

const Photo9TabletStylesCombined = {
  ...Photo9ContainerStyles,
  ...Photo9TabletStyles,
}

const Container = styled('div')`
  position: relative;
  margin-bottom: 300px;
  margin-right: -65px;

  @media screen and (max-width: ${theme.screenWidth.large}) {
    margin-bottom: 120px;
  }
`

const StyledBanner = styled(ParallaxBanner)`
  height: auto !important;
`

const FlexContainer = styled('div')`
  display: flex;

  @media screen and (max-width: ${theme.screenWidth.large}) {
    justify-content: center;
  }
`

export default function Mosaic({ photos }) {
  const screenSize = useWindowSize()

  const Photo1Container = styled.div(
    screenSize === 'tablet' ? Photo1TabletStylesCombined : Photo1ContainerStyles
  )
  const Photo2Container = styled.div(
    screenSize === 'tablet' ? Photo2TabletStylesCombined : Photo2ContainerStyles
  )
  const Photo3Container = styled.div(
    screenSize === 'tablet' ? Photo3TabletStylesCombined : Photo3ContainerStyles
  )
  const Photo4Container = styled.div(
    screenSize === 'tablet' ? Photo4TabletStylesCombined : Photo4ContainerStyles
  )
  const Photo5Container = styled.div(
    screenSize === 'tablet' ? Photo5TabletStylesCombined : Photo5ContainerStyles
  )
  const Photo6Container = styled.div(
    screenSize === 'tablet' ? Photo6TabletStylesCombined : Photo6ContainerStyles
  )
  const Photo7Container = styled.div(
    screenSize === 'tablet' ? Photo7TabletStylesCombined : Photo7ContainerStyles
  )
  const Photo8Container = styled.div(
    screenSize === 'tablet' ? Photo8TabletStylesCombined : Photo8ContainerStyles
  )
  const Photo9Container = styled.div(
    screenSize === 'tablet' ? Photo9TabletStylesCombined : Photo9ContainerStyles
  )

  const photoObjs = [
    {
      image: photos[0],
      amount: 0.2,
      component: Photo1Container,
      desktopStyles: Photo1ContainerStyles,
      tabletStyles: Photo1TabletStylesCombined,
      y: [-5, 5],
    },
    {
      image: photos[1],
      amount: 0.1,
      component: Photo2Container,
      desktopStyles: Photo2ContainerStyles,
      tabletStyles: Photo2TabletStylesCombined,
      y: [-30, 30],
    },
    {
      image: photos[2],
      amount: 0.15,
      component: Photo3Container,
      desktopStyles: Photo3ContainerStyles,
      tabletStyles: Photo3TabletStylesCombined,
      y: [25, -25],
    },
    {
      image: photos[3],
      amount: 0.2,
      component: Photo4Container,
      desktopStyles: Photo4ContainerStyles,
      tabletStyles: Photo4TabletStylesCombined,
      y: [-10, 10],
    },
    {
      image: photos[4],
      amount: 0.1,
      component: Photo5Container,
      desktopStyles: Photo5ContainerStyles,
      tabletStyles: Photo5TabletStylesCombined,
      y: [-5, 0],
    },
    {
      image: photos[5],
      amount: 0.2,
      component: Photo6Container,
      desktopStyles: Photo6ContainerStyles,
      tabletStyles: Photo6TabletStylesCombined,
      y: [-25, 25],
    },
    {
      image: photos[6],
      amount: 0.15,
      component: Photo7Container,
      desktopStyles: Photo7ContainerStyles,
      tabletStyles: Photo7TabletStylesCombined,
      y: [15, 0],
    },
    {
      image: photos[7],
      amount: 0.1,
      component: Photo8Container,
      desktopStyles: Photo8ContainerStyles,
      tabletStyles: Photo8TabletStylesCombined,
      y: [20, 0],
    },
    {
      image: photos[8],
      amount: 0.2,
      component: Photo9Container,
      desktopStyles: Photo9ContainerStyles,
      tabletStyles: Photo9TabletStylesCombined,
      y: [5, -5],
    },
  ]

  const row1 = [photoObjs[0], photoObjs[1]]
  const row2 = [photoObjs[2], photoObjs[3], photoObjs[4], photoObjs[5]]
  const row3 = [photoObjs[6], photoObjs[7], photoObjs[8]]
  const photoRows = [row1, row2, row3]

  // use exported ParallaxContext instead of useController()
  // useController() throws an error when rendered outside of the DOM e.g gatsby-ssr
  const parallaxContext = React.useContext(ParallaxContext)

  useEffect(() => {
    // the context value might be null, for example when rendered on a server / at build time
    let timeout = null
    if (parallaxContext) {
      timeout = setTimeout(() => {
        // update() called only on resize and mount
        parallaxContext.update()
      }, 1500)
    }
    return () => clearTimeout(timeout)
  }, [parallaxContext])

  return (
    <Container>
      {photoRows.map((row, i) => (
        <FlexContainer key={i}>
          {row.map((photo, y) => {
            const layer = { ...photo }
            layer.styles =
              screenSize === 'tablet' ? photo.tabletStyles : photo.desktopStyles
            const PhotoContainer = photo.component
            return (
              <Parallax
                key={y}
                y={photo.y}
                offsetXMax={0}
                offsetXMin={0}
                styleOuter={layer.styles}
              >
                <StyledBanner layers={[layer]}>
                  <PhotoContainer />
                </StyledBanner>
              </Parallax>
            )
          })}
        </FlexContainer>
      ))}
    </Container>
  )
}
