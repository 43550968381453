import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import axios from 'axios'
import Collapse from '@kunukn/react-collapse'
import CollapseIcon from '../../../images/accordion/collapse.svg'
import ExpandIcon from '../../../images/accordion/expand.svg'
import ArrowIcon from '../../../images/arrow-white.svg'
import '../../../styles/react-collapse.css'

const GeneralInquiryPostId = 'mp0q8ny'

const Title = styled('h3')`
  font-size: 48px;
  line-height: 73px;
  color: #fff;
  font-family: ${props => props.theme.fonts.primaryMedium};

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 24px;
    line-height: 1;
  }
`

const Container = styled('div')`
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 111px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-left: 0;
    padding-right: 0;
    margin-top: 53px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: 57px;
  }
`

const Panel = styled('div')`
  border-top: 1px solid rgba(66, 66, 66, 0.9);
  padding-bottom: 40px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-bottom: 36px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-bottom: 20px;
  }
`

const PanelHeader = styled('div')`
  font-size: 21px;
  line-height: 26px;
  color: ${props => props.textColor};
  font-weight: normal;
  font-family: ${props => props.theme.fonts.sourceCode};
  padding-top: 14px;
  letter-spacing: 1.3125px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 14px;
    line-height: 18px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 20px;
  }
`

const AccordionTitle = styled('div')`
  display: flex;
  justify-content: space-between;

  span {
    margin-right: 6px;
    text-transform: uppercase;
  }

  img {
    width: 16px;

    @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
      width: auto;
    }
  }
`

const Content = styled('p')`
  color: #fff;
  font-size: 24px;
  line-height: 27px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 18px;
    line-height: 20px;
    margin-top: 10px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 16px;
    line-height: 18px;
  }
`

const JobTitle = styled('h4')`
  font-family: ${props => props.theme.fonts.primaryRegular};
  font-size: 20px;
  line-height: 23px;
  color: #fff;
  margin-top: 13px;
  margin-right: 35%;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 18px;
    line-height: 21px;
    margin-top: 6px;
    margin-right: 45%;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 16px;
    line-height: 19px;
    margin-right: 0;
    margin-top: 7px;
  }
`

const ColoredHeading = styled('h5')`
  font-family: ${props => props.theme.fonts.sourceCode};
  color: ${props => props.textColor};
  font-size: 21px;
  line-height: 26px;
  letter-spacing: 1.3125px;
  text-transform: uppercase;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 14px;
    line-height: 18px;
  }
`

const PostContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    flex-direction: column;
  }
`

const UnderlinedText = styled('span')`
  border-bottom: 1px solid #fff;
  margin-right: 3px;
`

const SideContent = styled(Content)`
  margin-top: 5px;
  margin-bottom: 18px;
  font-size: 21px;
  line-height: 23px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 18px;
    line-height: 20px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: 0;
  }
`

const Link = styled(SideContent)`
  line-height: 36px;
  text-decoration: none;

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-bottom: 1px;
  }
`

const DescriptionContainer = styled('div')`
  flex-basis: 472px;
  flex-shrink: 1;
  margin-right: 50px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    flex-basis: 328px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-right: 0;
    flex-basis: auto;
  }
`

const MetaContainer = styled('div')`
  flex-shrink: 0;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    margin-right: 47px;
  }
`

const AccordionContainer = styled('div')`
  margin-top: 15px;
`

const GeneralPostLink = styled('a')`
  font-size: 26px;
  line-height: 36px;
  border-bottom: 1px solid #fff;
  color: #fff;
  text-decoration: none;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 18px;
    line-height: 36px;
  }
`

const GeneralPostMessage = styled('p')`
  font-family: ${props => props.theme.fonts.sourceCode};
  color: rgba(193, 28, 92, 0.9);
  font-size: 21px;
  line-height: 26px;
  letter-spacing: 1.31px;
  text-transform: uppercase;
  margin-top: 24px;
  margin-bottom: 13px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
  }
`

// Convert position_type to title case (eg. full_time => Full time)
const toTitleCase = str =>
  str
    .replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    )
    .replace(/_/g, ' ')

export default function RecruiterBoxListings({
  departments = [],
  generalPostMessage = '',
  generalPostCtaText = '',
  generalPostApplicationHref = '',
}) {
  const [listedPosts, setListedPosts] = useState([])
  const [generalPost, setGeneralPost] = useState({})
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    axios.get(`${process.env.GATSBY_LAMBDA_API_URL}/jobs`).then(res => {
      const posts = []
      res.data.forEach(post => {
        if (post.id === GeneralInquiryPostId) {
          setGeneralPost(post)
          return
        }
        posts.push(post)
      })
      setListedPosts(posts)
    })
  }, [])

  const onToggle = index => {
    if (activeIndex === index) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index)
    }
  }

  return (
    <Container>
      <Title>Current openings</Title>
      <AccordionContainer>
        {listedPosts.map((post, i) => {
          const {
            description,
            additional_fields,
            title,
            hosted_url,
            position_type,
            allows_remote,
          } = post
          const paragraphs = description.split('<p>')
          const shortenedDescription = paragraphs[1].replace(/<[^>]+>/g, '')
          const deptWithColor = departments.find(department =>
            department.name.includes(additional_fields.department)
          ) || { color: '#8FA1A8' }
          const textColor = deptWithColor.color
          const formattedContractType = toTitleCase(position_type)

          return (
            <Panel key={i}>
              <PanelHeader
                onClick={() => onToggle(i)}
                isOpen={activeIndex === i}
                textColor={textColor}
              >
                <AccordionTitle>
                  <span>{additional_fields.department || 'Other'}</span>{' '}
                  <img
                    src={activeIndex === i ? CollapseIcon : ExpandIcon}
                    alt=""
                  />
                </AccordionTitle>
                <JobTitle>{title}</JobTitle>
              </PanelHeader>
              <Collapse
                className="collapse-css-transition"
                isOpen={activeIndex === i}
              >
                <PostContainer>
                  <DescriptionContainer>
                    <Content
                      dangerouslySetInnerHTML={{ __html: shortenedDescription }}
                    />
                  </DescriptionContainer>
                  <MetaContainer>
                    <ColoredHeading textColor={textColor}>
                      Contract Type
                    </ColoredHeading>
                    <SideContent>{formattedContractType}</SideContent>
                    <ColoredHeading textColor={textColor}>
                      Location
                    </ColoredHeading>
                    <SideContent>
                      {allows_remote ? 'Remote' : 'On site'}
                    </SideContent>
                    <ColoredHeading textColor={textColor}>
                      Interested?
                    </ColoredHeading>
                    <Link
                      as="a"
                      href={hosted_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <UnderlinedText>Apply now</UnderlinedText>{' '}
                      <img src={ArrowIcon} alt="" />
                    </Link>
                  </MetaContainer>
                </PostContainer>
              </Collapse>
            </Panel>
          )
        })}
        <Panel>
          <GeneralPostMessage>{generalPostMessage}</GeneralPostMessage>
          <GeneralPostLink
            href={generalPost.hosted_url || generalPostApplicationHref}
            target="_blank"
            rel="noopener noreferrer"
          >
            {generalPostCtaText}
          </GeneralPostLink>
        </Panel>
      </AccordionContainer>
    </Container>
  )
}
